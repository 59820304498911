import axios from "axios";
import auth from "../auth";

// API LOCATION
const baseUrl = process.env.REACT_APP_API + "/api/";


let api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const statusCode = error.response ? error.response.status : null;
    // go to login if error is a 401
    if (statusCode === 401) {
        localStorage.removeItem("userState");
        localStorage.removeItem("budState");
        auth.logout("/login")
    }
    return Promise.reject(error);
})

export default api;