import users_api from "./users";
import bud_api from "./bud";

const getCurrentUserLocal = () => {
    let userState = localStorage.getItem('userState')
    if (userState === "undefined") logout("/login")
    return JSON.parse(localStorage.getItem('userState'));
}

const isAuthenticated = () => {
    // check for token
    const user = getCurrentUserLocal();

    if (user && user.email) {
        return true;
    } else {
        return false;
    }
}

const isBudAuthenticated = () => {
    // check for bud
    return CheckBud();
}

const CheckBud = async () => {
    let response = bud_api.budIsAuthenticated();
    response.then(
        response => {
            return !!response.data.budUser
        }
    ).catch((err) => {
        console.log(err)
        return false
    })
}

const logout = (url) => {
    let response = users_api.logoutUser();
    response.then(
        () => {
            localStorage.removeItem("userState");
            if (url) window.location.assign(url);
        }
    ).catch((err) => {
        console.log(err)
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logout,
    getCurrentUserLocal,
    isAuthenticated,
    isBudAuthenticated,
    CheckBud
};
