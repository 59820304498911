import { useEffect, useState } from "react";

import SideNav from "../../components/SideNav";
import createReport from "../../utils/createReport";

import users_api from "../../api/users";
import outgoings_api from "../../api/outgoings";
import contacts_api from "../../api/contacts";
import documents_api from "../../api/documents";

import ExecutorReport from "../../components/ExecutorReport";
import FinancialReport from "../../components/FinancialReport";

const Reports = () => {

    const [executorReport, setExecutorReport] = useState()
    const [financialReport, setFinancialReport] = useState()

    const [transactions, setTransactions] = useState()
    const [contacts, setContacts] = useState()
    const [accounts, setAccounts] = useState()
    const [documents, setDocuments] = useState()
    const [user, setUser] = useState()

    const [reportsMenu, setReportsMenu] = useState();
    const [showSection, setShowSection] = useState(0);

    useEffect(() => {
        let menus = [];

        let menu = {};
        menu.name = "Executor Snapshot";
        menu.activeClass = "active link report-menu-item";
        menus.push(menu);

        menu = {};
        menu.name = "Financial Snapshot";
        menu.activeClass = "link report-menu-item";
        menus.push(menu);

        setReportsMenu(menus);
    }, []);

    useEffect(() => {
        getTransactions()
        GetContacts()
        getUser()
        getAccounts()
        GetDocuments()
    }, [])

    useEffect(() => {
        if (!transactions || !contacts || !user || !accounts || !documents) return;
        setExecutorReport(createReport.generateExecutorDocument(transactions, contacts, user, accounts, documents))
        setFinancialReport(createReport.generateFinancialDocument(transactions, contacts, user, accounts))
    }, [transactions, contacts, user, accounts, documents])

    const getUser = () => {
        let response = users_api.getProfile();
        response.then(
            response => {
                setUser(response.data.profile.user)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const getAccounts = () => {
        let response = outgoings_api.getAccounts();
        response.then(
            response => {
                setAccounts(response.data.accounts)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const getTransactions = () => {
        let response = outgoings_api.getRegularTransactions();
        response.then(
            response => {
                setTransactions(response.data.transactions)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const GetContacts = () => {
        let response = contacts_api.getContacts();
        response.then(
            response => {
                setContacts(response.data.contacts)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const GetDocuments = () => {
        let response = documents_api.getDocuments();
        response.then(
            response => {
                setDocuments(response.data.documents)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const clickMenu = (ev) => {
        if (ev.target.id === "") return;
        updateMenu(ev.target.textContent);
    }

    const updateMenu = (selection) => {
        if (!reportsMenu) return;
        let updateMenu = [...reportsMenu];
        let idx = updateMenu.map(item => item.name).indexOf(selection);
        for (let i = 0; i < updateMenu.length; i++) {
            if (i !== idx) {
                updateMenu[i].activeClass = "link report-menu-item";
            }
            else {
                updateMenu[i].activeClass = "active link report-menu-item";
                setShowSection(i);
            }
        }
        setReportsMenu(updateMenu);
    }

    useEffect(() => {
        isPlus()
    }, [])

    const isPlus = () => {
        let response = users_api.usersSubscription();
        response.then(
            response => {
                let subscription = response.data.subscription;
                if (!subscription) window.location.assign("/dashboard?reason=membership");
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div className="page-container">
            <SideNav />
            <div className="reports-subpage-container">
                <div className="reports-subpage-navigation-container">
                    <div className="reports-subpage-navigation-title">
                        Your Reports
                    </div>
                    <div className="reports-menu">
                        {executorReport && financialReport
                            ?
                            <>
                                {reportsMenu && reportsMenu.map((menu, idx) => {
                                    return (
                                        <div className={menu.activeClass} id={menu.name} onClick={clickMenu}>{menu.name}</div>
                                    )
                                })}
                            </>
                            :
                            transactions || contacts || user || accounts || documents ?
                                "Loading..."
                                :
                                "You currently have no reports"
                        }
                    </div>
                </div>
                <ExecutorReport report={executorReport} show={showSection === 0} />
                <FinancialReport report={financialReport} show={showSection === 1} />
                {/*<div className="reports-subpage-preview-container">*/}
                {/*    {executorReport && !executorReport ? null : <PDFViewer className="report-viewer">{executorReport && executorReport}</PDFViewer> }*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Reports