import transaction_placeholder from "../assets/transaction-placeholder.webp"
import dateUtils from "../utils/dateUtils";
import currencyUtils from "../utils/currencyUtils";
import { useEffect, useState } from "react";

import AddOutgoingForm from "./AddOutgoingForm";
import TransactionDetailsDialogue from "./TransactionDetailsDialogue";
import TransactionHideConfirmation from "./TransactionHideConfirmation";
import TransactionUnhideConfirmation from "./TransactionUnhideConfirmation";
import outgoings_api from "../api/outgoings";

import refresh from "../assets/refresh.webp"


const TransactionsTable = (props) => {

    const tableName = props.tableName
    const refreshTransactions = props.refreshTransactions

    let hiddenTransactionTable = false
    if (props.hiddenTransactionTable === true) hiddenTransactionTable = true

    const [showTransactionTableDetails, setShowTransactionTableDetails] = useState(true)
    //

    const [transactions, seTransactions] = useState()
    const [accounts, setAccounts] = useState()

    const [display, setDisplay] = useState(false)

    const [optionsDialogueConfig, setOptionsDialogueConfig] = useState()
    const [selectedTransaction, setSelectedTransaction] = useState()

    const [displayOptionsDialogue, setDisplayOptionsDialogue] = useState(false)
    const [displayHideDialogue, setDisplayHideDialogue] = useState(false)
    const [displayUnhideDialogue, setDisplayUnhideDialogue] = useState(false)
    const [displayDetailsDialogue, setDisplayDetailsDialogue] = useState(false)

    useEffect(() => {
        if (!props.accounts) return
        setAccounts(props.accounts)
    }, [props.accounts])

    useEffect(() => {
        if (!props.transactions) return
        seTransactions(props.transactions)
        if (props.hiddenTransactionTable) setShowTransactionTableDetails(false)
    }, [props.transactions])

    const UpdateDisplay = (ev) => {
        if (ev.target.id !== "toggle") return;
        setDisplay(!display);
    }

    useEffect(() => {
        let elem = document.getElementById("scroll-event-holder")
        if (!elem) return;
        elem.addEventListener("wheel", (event) => { });

        elem.onwheel = (event) => {
            setDisplayOptionsDialogue(false);
        };
    }, [document.getElementById("scroll-event-holder")])


    const openDetailsDialog = (regular_transaction) => {
        let button = document.getElementById(regular_transaction.label)

        let posTop = button ? button.offsetTop - document.getElementById("scroll-container").scrollTop : 500
        let posLeft = button ? button.offsetLeft : 500

        setOptionsDialogueConfig({ top: posTop + 'px', left: (posLeft - 170) + 'px' })
        setSelectedTransaction(regular_transaction)
        setDisplayOptionsDialogue(true)
    }

    const HandleHideTransaction = (ev) => {
        if (!selectedTransaction) return
        let response = outgoings_api.hideTransaction(selectedTransaction.label);
        response.then(
            () => {
                refreshTransactions()
            }
        ).catch((err) => {
            console.log(err)
        })
        setDisplayHideDialogue(false)
    }

    const HandleUnhideTransaction = (ev) => {
        if (!selectedTransaction) return
        let response = outgoings_api.unhideTransaction(selectedTransaction.label);
        response.then(
            () => {
                refreshTransactions()
            }
        ).catch((err) => {
            console.log(err)
        })
        setDisplayUnhideDialogue(false)
    }


    const HandleOpenDetailsDialogue = (ev) => {
        if (ev.target.id !== "details") return;
        setDisplayOptionsDialogue(false)
        setDisplayDetailsDialogue(true)
    }

    const HandleOpenHideDialogue = (ev) => {
        if (ev.target.id !== "hide") return;
        setDisplayOptionsDialogue(false)
        setDisplayHideDialogue(true)
    }

    const HandleCloseDetailsDialogue = (ev) => {
        if (ev.target.id !== "close") return;
        setDisplayDetailsDialogue(false)
    }

    const HandleCloseHideDialogue = () => {
        setDisplayHideDialogue(false)
    }

    const HandleCloseUnhideDialogue = (ev) => {
        setDisplayUnhideDialogue(false)
    }

    const openUnhideDialog = (regular_transaction) => {
        setSelectedTransaction(regular_transaction)
        setDisplayUnhideDialogue(true)
    }

    const returnStarPayee = (payee) => {
        return payee[0] + "*********"
    }

    const returnStarLastDate = () => {
        return "** ******* ****"
    }

    const returnStarBank = () => {
        return "****"
    }

    const toggleSeeData = (ev) => {
        if (ev.target.id !== "toggle") return;
        setShowTransactionTableDetails(!showTransactionTableDetails)
    }

    return (
        <>
            {displayOptionsDialogue ?
                <div className="transaction-table-dialogue" style={optionsDialogueConfig}>
                    <div className="transaction-table-dialogue-buttons">
                        <button onClick={HandleOpenDetailsDialogue} id="details">More Details</button>
                        <button onClick={HandleOpenHideDialogue} id="hide">Hide record</button>
                    </div>
                </div>
                :
                null
            }
            <div className="transaction-table-element-container" id={"scroll-event-holder"}>
                <div className="transaction-table-title-container">
                    <h2 className="transaction-table-title">{tableName}</h2>
                    {hiddenTransactionTable ?
                        <img src={showTransactionTableDetails ? "/images/eye_closed.png" : "/images/eye.png"} id="toggle" onClick={toggleSeeData}
                            className="link" alt="show hide data image" />
                        :
                        null
                    }
                </div>

                <div className="transaction-table-container">
                    <div className="table-headers-container tr">
                        <div className="table-header-cell-container name th">
                            <div className="table-header-word"> Payee </div>
                        </div>
                        <div className="table-header-cell-container date th">
                            <div className="table-header-word"> Last date </div>
                        </div>
                        <div className="table-header-cell-container amount th">
                            <div className="table-header-word"> Amount </div>
                        </div>
                        <div className="table-header-cell-container bank th">
                            <div className="table-header-word"> Bank </div>
                        </div>
                    </div>
                    <div className="table-rows-container">
                        {transactions && transactions.map((regular_transaction, idx) => {
                            return (
                                <div className="table-row-container">
                                    <div className="table-body-cell name">
                                        <img src={regular_transaction.merchant_logo && regular_transaction.merchant_logo !== ''
                                            ? regular_transaction.merchant_logo
                                            : transaction_placeholder}
                                            style={{ width: "40px !important" }}
                                        />
                                        <div className="table-body-cell-name">
                                            {regular_transaction.merchant_name != undefined && regular_transaction.merchant_name != ""
                                                ?
                                                (showTransactionTableDetails
                                                    ? regular_transaction.merchant_name
                                                    : returnStarPayee(regular_transaction.merchant_name))
                                                :
                                                (showTransactionTableDetails
                                                    ? regular_transaction.transaction_description
                                                    : returnStarPayee(regular_transaction.transaction_description))

                                            }
                                        </div>
                                    </div>

                                    <div className="table-body-cell date">
                                        <div className="table-body-cell-date">
                                            {(showTransactionTableDetails
                                                ? dateUtils.formatDateLong(regular_transaction.date)
                                                : returnStarLastDate())
                                            }
                                        </div>
                                    </div>

                                    <div className="table-body-cell amount" >
                                        <div className="table-body-cell-amount">
                                            {regular_transaction.currency && regular_transaction.amount && (showTransactionTableDetails
                                                ? currencyUtils.formatCurrencyString(regular_transaction.currency, regular_transaction.amount)
                                                : currencyUtils.formatCurrencyStringStar(regular_transaction.currency, regular_transaction.amount))
                                            }
                                        </div>
                                    </div>

                                    <div className="table-body-cell bank">
                                        <div className="table-body-cell-bank">
                                            {(showTransactionTableDetails
                                                ? regular_transaction.provider
                                                : returnStarBank())
                                            }
                                        </div>
                                    </div>
                                    {!hiddenTransactionTable ?
                                        <div className="table-body-cell more" id={regular_transaction.label}
                                            onClick={() => {
                                                openDetailsDialog(regular_transaction)
                                            }}>
                                        </div>
                                        :
                                        <div className="table-body-cell refresh link" id={regular_transaction.label}
                                            onClick={() => {
                                                openUnhideDialog(regular_transaction)
                                            }}>
                                            <img src={refresh} />
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
                {transactions && !hiddenTransactionTable ?
                    <div className="transaction-table-total-amount-container">
                        <div className="transaction-table-total-amount-label">
                            Total Outgoings: £{transactions.reduce((total, transaction) => total + transaction.amount, 0).toFixed(2)}
                        </div>
                    </div>
                    :
                    null}
                {!hiddenTransactionTable ?
                    <div className="transaction-table-something-missing-container">
                        <div className="transaction-table-something-missing-label">
                            Something missing?
                        </div>
                        <div className="transaction-table-something-missing-add" id="toggle" onClick={UpdateDisplay}>
                            Add outgoing
                        </div>
                    </div>
                    :
                    null}
            </div>
            {!hiddenTransactionTable ?
                <>
                    <AddOutgoingForm display={display} updateDisplay={UpdateDisplay.bind(this)} accounts={accounts} refreshTransactions={refreshTransactions} />
                    <TransactionDetailsDialogue display={displayDetailsDialogue} closeDisplay={HandleCloseDetailsDialogue.bind(this)} transaction={selectedTransaction} />
                    <TransactionHideConfirmation display={displayHideDialogue} closeDisplay={HandleCloseHideDialogue.bind(this)} hideTransaction={HandleHideTransaction.bind(this)} />
                </>
                :
                <>
                    <TransactionUnhideConfirmation display={displayUnhideDialogue} closeDisplay={HandleCloseUnhideDialogue.bind(this)} unhideTransaction={HandleUnhideTransaction.bind(this)} />
                </>
            }
        </>
    )
}

export default TransactionsTable