import {useEffect, useState} from "react";
import currencyUtils from "../utils/currencyUtils"
import dateUtils from "../utils/dateUtils"

import close from "../assets/close.webp"

const TransactionDetailsDialogue = (props) => {

    const closeDisplay = props.closeDisplay

    const [display, setDisplay] = useState()
    const [transaction, setTransaction] = useState()

    useEffect(() => {
        if (!props.transaction) return;
        setTransaction(props.transaction)
    }, [props.transaction])

    useEffect(() => {
        setDisplay(props.display)
    }, [props.display])

    return (
        <>
            {display ?
                <>
                    <div className="page-mask"/>
                    <div className="transaction-details-dialogue">
                        <div className="transaction-details-dialogue-top-container">
                            <div className="transaction-details-dialogue-heading-container">
                                <div className="transaction-details-dialogue-heading">
                                    {transaction.merchant_name && transaction.merchant_name != "" ?
                                        transaction.merchant_name : transaction.transaction_description}
                                </div>
                            </div>
                            <button id="close" onClick={closeDisplay} className="transaction-details-dialogue-close">
                                <img src={close} id="close"/>
                            </button>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Bank
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {transaction.provider}
                            </div>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Amount
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {transaction.currency && transaction.amount
                                    && currencyUtils.formatCurrencyString(transaction.currency, transaction.amount)}
                            </div>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Frequency
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {transaction.periodicity && transaction.periodicity.charAt(0).toUpperCase()
                                    + transaction.periodicity.substr(1).toLowerCase()}
                            </div>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Last transaction
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {dateUtils.formatDateLong(transaction.date)}
                            </div>
                        </div>

                    </div>
                </>

                :
                null
            }
        </>
    )
}

export default TransactionDetailsDialogue