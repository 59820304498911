import isa from "../assets/documents/assets/isa.webp"
import property from "../assets/documents/assets/mortgage.webp"
import bonds from "../assets/documents/assets/savings-bonds.webp"
import pension from "../assets/documents/assets/pension.webp"
import shares from "../assets/documents/assets/shares.webp"

import will from "../assets/documents/future/will.webp"
import letter_of_wishes from "../assets/documents/future/letter-of-wishes.webp"
import organ_donation from "../assets/documents/future/organ-donation.webp"
import power_of_attorney from "../assets/documents/future/power-of-attorney.webp"
import trusts from "../assets/documents/future/trusts.webp"

import health_insurance from "../assets/documents/insurance/health-insurance.webp"
import life_insurance from "../assets/documents/insurance/life-insurance.webp"
import other_insurance from "../assets/documents/insurance/other-insurance.webp"

import id from "../assets/documents/other/id.webp"
import passwords from "../assets/documents/other/passwords.webp"

const ISA = {
    "title": "ISA",
    "word": "ISA",
    "description": "An ISA will end when either, your executor closes it or the administration of your estate " +
        "is completed. Otherwise, your ISA provider will close your ISA 3 years and 1 day after you die. " +
        "There will be no Income Tax or Capital Gains Tax to pay up to that date, but ISA investments " +
        "will form part of your estate for Inheritance Tax purposes.",
    "image": isa,
    "link": "isa"
};

const PROPERTY = {
    "title": "Property",
    "word": "Property",
    "description": "Property is counted as part of your estate. There is a ‘main residence nil-rate band’ rule. This " +
        "provides an extra tax-free allowance of £175,000 on top of the £325,000 nil-rate band making the total " +
        "£500,000, provided that the house is being left to a direct descendent such as child, step-child, " +
        "foster child, adopted child or grandchild.",
    "image": property,
    "link": "property"
};

const SAVINGS_BONDS = {
    "title": "Savings Bonds",
    "word": "Savings Bond",
    "description": "Fixed-rate savings bonds are interest-paying savings accounts offered by banks and building " +
        "societies for a fixed amount of time. If the Fixed Rate Bond is held in your sole name, it can be closed it " +
        "early and the interest that's built up paid out. It can also be kept open until it reaches the end of its fixed" +
        " term. If kept open, the account has to be left in your name.",
    "image": bonds,
    "link": "savings-bonds"
};

const PREMIUM_BONDS = {
    "title": "Premium Bonds",
    "word": "Premium Bond",
    "description": "Premium Bonds are an investment product issued by National Savings and Investment. Unlike " +
        "other investments, where you earn interest or a regular dividend income, you are entered into a monthly prize " +
        "draw where you can win between £25 and £1 million tax free. NS&I does allow them to be held by the estate " +
        "for one year after death and during this time they will still be entered into the prize draw each month.",
    "image": bonds,
    "link": "premium-bonds"
};

const OTHER_ASSETS = {
    "title": "Other",
    "word": "Other",
    "description": "This form may be used for logging any other assets. These may include Jewellery, vehicles, " +
        "Artworks, Coin collections, in fact any other asset that you wish to be known to your executors and whom you " +
        "might want to bequeath it to. (The bequest would need to be specifically entered into " +
        "the will to be legally binding)",
    "image": bonds,
    "link": "other-assets"
};

const PENSION = {
    "title": "Pension",
    "word": "Pension",
    "description": "Adding pension information will help your executors get the pension benefit quickly to those you " +
        "wish to leave it to. Defined benefit pensions usually provide a pension income to a partner, or another " +
        "financial dependant, after you die. Beneficiaries can be nominated.",
    "image": pension,
    "link": "pension"
};

const SHARES = {
    "title": "Shares",
    "word": "Share",
    "description": "Did you know? Under the default ‘Articles of association’ rules, the person who is left the shares " +
        "can decide to become a shareholder or transfer them to someone else. During the period the person who has been " +
        "left the shares is entitled to the benefits of those shares, such as dividends, " +
        "but is not entitled to vote as a shareholder.",
    "image": shares,
    "link": "shares"
};

const CRYPTO = {
    "title": "Crypto",
    "word": "Crypto",
    "description": "Your cryptocurrency is an asset that forms part of your estate. cryptocurrency is not " +
        "automatically transferred upon death, the person’s executor would need to access the crypto so it is " +
        "essential that relevant access codes are stored securely where an executor can retrieve them. Despite the " +
        "advanced technology behind cryptocurrency, the method for distributing a person’s " +
        "cryptocurrency remains old-school.",
    "image": shares,
    "link": "crypto"
};

const WILL = {
    "title": "Will",
    "word": "Will",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": will,
    "link": "will"
};

const LETTER_OF_WISHES = {
    "title": "Letter of Wishes",
    "word": "Letter of Wishes",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": letter_of_wishes,
    "link": "letter-of-wishes"
};

const ORGAN_DONATION = {
    "title": "Organ Donation",
    "word": "Organ Donation",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": organ_donation,
    "link": "organ-donation"
};

const POWER_OF_ATTORNEY = {
    "title": "Power of Attorney",
    "word": "Power of Attorney",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": power_of_attorney,
    "link": "power-of-attorney"
};

const TRUSTS = {
    "title": "Trusts",
    "word": "trust",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": trusts,
    "link": "trusts"
};

const DRIVERS_LICENCE = {
    "title": "Driver's Licence",
    "word": "Driver's Licence",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": id,
    "link": "drivers-licence"
};

const PASSPORT = {
    "title": "Passport",
    "word": "Passport",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": id,
    "link": "passport"
};

const PASSWORDS = {
    "title": "Password vault",
    "word": "password vault",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": passwords,
    "link": "passwords"
};


const PRIVATE_MEDICAL = {
    "title": "Private Medical",
    "word": "Private Medical",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": health_insurance,
    "link": "private-medical"
};

const LIFE_INSURANCE = {
    "title": "Life Insurance",
    "word": "life insurance",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": life_insurance,
    "link": "life-insurance"
};

const CRITICAL_ILLNESS = {
    "title": "Critical Illness",
    "word": "Critical Illness",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": health_insurance,
    "link": "critical-illness"
};

const DEATH_IN_SERVICE = {
    "title": "Death in Service",
    "word": "Death in Service",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": health_insurance,
    "link": "death-in-service"
};

const INCOME_PROTECTION = {
    "title": "Income Protection",
    "word": "Income Protection",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": other_insurance,
    "link": "income-protection"
};

const DISABILITY = {
    "title": "Disability",
    "word": "Disability",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": health_insurance,
    "link": "disability"
};

const OTHER_INSURANCE = {
    "title": "Other Insurance",
    "word": "other insurance",
    "description": "Lorem ipsum dolor consectetur adipiscing elit magna. Lorem ipsum dolor consectetur adipiscing " +
        "elit magna. Lorem ipsum dolor consectetur adipiscing elit magna.",
    "image": other_insurance,
    "link": "other-insurance"
};


const ASSETS_AND_LIABILITIES = {
    "Pension": PENSION,
    "Savings Bonds": SAVINGS_BONDS,
    "Premium Bonds": PREMIUM_BONDS,
    "Shares": SHARES,
    "ISA": ISA,
    "Crypto": CRYPTO,
    "Property" : PROPERTY,
    "Other": OTHER_ASSETS
};

const FUTURE_PLANNING = {
    "Will": WILL,
    "Letter of Wishes" : LETTER_OF_WISHES,
    "Power of Attorney": POWER_OF_ATTORNEY,
    "Organ Donation": ORGAN_DONATION,
    "Trusts": TRUSTS,
    "Driver's Licence" : DRIVERS_LICENCE,
    "Passport" : PASSPORT,
    "Passwords": PASSWORDS,
};

const INSURANCE = {
    "Private Medical": PRIVATE_MEDICAL,
    "Life Insurance" : LIFE_INSURANCE,
    "Critical Illness": CRITICAL_ILLNESS,
    "Death In Service": DEATH_IN_SERVICE,
    "Income Protection": INCOME_PROTECTION,
    "Disability": DISABILITY,
    "Other Insurance": OTHER_INSURANCE,
};


const LINKS = {
    "isa": ISA,
    "property": PROPERTY,
    "savings-bonds": SAVINGS_BONDS,
    "premium-bonds": PREMIUM_BONDS,
    "pension": PENSION,
    "shares": SHARES,
    "other-assets": OTHER_ASSETS,
    "crypto": CRYPTO,

    "will": WILL,
    "letter-of-wishes": LETTER_OF_WISHES,
    "organ-donation": ORGAN_DONATION,
    "power-of-attorney": POWER_OF_ATTORNEY,
    "trusts": TRUSTS,
    "drivers-licence": DRIVERS_LICENCE,
    "passport": PASSPORT,
    "passwords": PASSWORDS,

    "private-medical": PRIVATE_MEDICAL,
    "life-insurance": LIFE_INSURANCE,
    "critical-illness": CRITICAL_ILLNESS,
    "death-in-service": DEATH_IN_SERVICE,
    "income-protection": INCOME_PROTECTION,
    "disability": DISABILITY,
    "other-insurance": OTHER_INSURANCE,
}

export default {
    LINKS,

    ASSETS_AND_LIABILITIES,
    FUTURE_PLANNING,
    INSURANCE,

    ISA,
    PROPERTY,
    SAVINGS_BONDS,
    PREMIUM_BONDS,
    OTHER_ASSETS,
    CRYPTO,
    PENSION,
    SHARES,

    WILL,
    LETTER_OF_WISHES,
    ORGAN_DONATION,
    POWER_OF_ATTORNEY,
    TRUSTS,
    DRIVERS_LICENCE,
    PASSPORT,
    PASSWORDS,

    PRIVATE_MEDICAL,
    LIFE_INSURANCE,
    OTHER_INSURANCE,
    CRITICAL_ILLNESS,
    DEATH_IN_SERVICE,
    INCOME_PROTECTION,
    DISABILITY
}