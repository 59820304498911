import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import outgoings_api from "../../api/outgoings";
import bud_api from "../../api/bud";
import users_api from "../../api/users";
import auth from "../../api/auth";

import SideNav from "../../components/SideNav";
import TransactionsTable from "../../components/TransactionsTable";
import AccountDetailsDialogue from "../../components/AccountDetailsDialogue";

import bud_banner from "../../assets/budBanners/budbanner.webp"

import plus from "../../assets/plus.webp";
import refresh_image from "../../assets/refresh.webp"
import bank_card_chip from "../../assets/bank-card-chip.webp"

import "swiper/css";
import "swiper/css/navigation";

import { useSearchParams } from "react-router-dom";

import BANK_LOGOS from "../../models/BankLogos";


const Banking = () => {

    const [isAuthenticated, setIsAuthenticated] = useState();
    const [isBudAuthenticated, setIsBudAuthenticated] = useState(false);

    const [transactions, setTransactions] = useState();
    const [accounts, setAccounts] = useState();

    const [detailsAccount, setDetailsAccount] = useState();
    const [displayAccountDetails, setDisplayAccountDetails] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const [slidesPerView, setSlidesPerView] = useState(3);
    const [disableRefresh, setDisableRefresh] = useState(false);

    useEffect(() => {
        let authenticated = auth.isAuthenticated()
        if (!authenticated) window.location.assign("/login");
        else setIsAuthenticated(authenticated);

        let budAuthenticated = auth.isBudAuthenticated()
        setIsBudAuthenticated(budAuthenticated);

        getAccounts();
        getTransactions();

        const handleResize = () => {
            let width = Math.min(window.innerWidth - 550, 1200);
            let num = Math.floor(width / 220);
            setSlidesPerView(num);
        }
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (searchParams.get("status") === "success") {
            refreshFinancialsData();
            checkBudAuthentication();
        }
    }, [searchParams.get("status")])

    const checkBudAuthentication = () => {
        auth.CheckBud();
        let budAuthenticated = auth.isBudAuthenticated()
        setIsBudAuthenticated(budAuthenticated);
    }

    const getAccounts = () => {
        let response = outgoings_api.getAccounts();
        response.then(
            response => {
                setAccounts(response.data.accounts)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const getTransactions = () => {
        let response = outgoings_api.getRegularTransactions();
        response.then(
            response => {
                setTransactions(response.data.transactions)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const refreshFinancialsData = () => {
        setDisableRefresh(true);
        let response = bud_api.budRefresh();
        response.then(
            response => {
                getAccounts()
                getTransactions()
                setDisableRefresh(false);
            }
        ).catch((err) => {
            setDisableRefresh(false);
            console.log(err)
        })
    }

    const thisBudAuthenticate = (ev) => {
        if (ev.target.id !== "bud") return
        let response = bud_api.budAuthenticate();
        response.then(
            response => {
                window.location.assign(response.data.url);
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const openDetailsDialog = (ev) => {
        if (!ev.target.id && ev.target.id !== 0) return;
        setDetailsAccount(accounts[ev.target.id])
        toggleDetailsDialogue()
    }

    const toggleDetailsDialogue = () => {
        setDisplayAccountDetails(!displayAccountDetails)
    }

    const handleRefreshFinancialsData = (ev) => {
        if (ev.target.id !== "refresh") return
        refreshFinancialsData();
    }

    useEffect(() => {
        isPlus()
    }, [])

    const isPlus = () => {
        let response = users_api.usersSubscription();
        response.then(
            response => {
                let subscription = response.data.subscription;
                if (!subscription) window.location.assign("/dashboard?reason=membership");
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div className="page-container" id="scroll-container">
            <SideNav />
            <div className="outgoings-subpage-container">
                {!isBudAuthenticated || accounts && transactions && (accounts.length === 0 && transactions.length === 0) || !accounts
                    ?
                    <div className="bud-banner-container link" onClick={thisBudAuthenticate} id="bud">
                        <img src={bud_banner} id="bud" />
                    </div>
                    :
                    <>
                        <div className="bank-accounts-container">
                            <div className="bank-accounts-top-container">
                                <h3 className="bank-accounts-title">Bank accounts</h3>
                                <button className="bank-accounts-refresh-button" id="refresh"
                                    onClick={handleRefreshFinancialsData} disabled={disableRefresh}>
                                    <img src={refresh_image} alt="refresh accounts icon" id="refresh" />
                                    <div className="bank-accounts-refresh-button-text" id="refresh">
                                        Refresh accounts</div>
                                </button>
                                <button className="bank-accounts-add-button" id="bud" onClick={thisBudAuthenticate}>
                                    <img src={plus} alt="add account icon" id="bud" />
                                    <div className="bank-accounts-add-button-text" id="bud">
                                        Add account</div>
                                </button>
                            </div>
                            <div className="bank-accounts-bottom-container">
                                <div className="bank-accounts-cards-container">
                                    <Swiper
                                        slidesPerView={slidesPerView}
                                        spaceBetween={20}
                                        className="bank-accounts"
                                        navigation={true}
                                        modules={[Navigation]}>
                                        {accounts && accounts.map((account, idx) => {
                                            return (
                                                <SwiperSlide>
                                                    <div className={"bank-account-card " + account.provider + " link"} id={idx} onClick={openDetailsDialog}>
                                                        <div className="bank-account-card-top-container" id={idx}>
                                                            <div className="bank-account-chip" id={idx}>
                                                                <img src={bank_card_chip} id={idx} />
                                                            </div>
                                                            <div className="bank-account-logo" id={idx}>
                                                                <img id={idx}
                                                                    src={account.logo !== "nologo"
                                                                        ? account.logo
                                                                        : BANK_LOGOS[account.provider]} />
                                                            </div>
                                                        </div>
                                                        <div className="bank-account-provider-card" id={idx}> {account.provider}</div>
                                                        <div className="bank-account-id-card" id={idx}>
                                                            {"****" + account.account_number[5] + account.account_number[6]
                                                                + account.account_number[7]}</div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                                <div className="mobile-accounts-cards-container">
                                    {accounts && accounts.map((account, idx) => {
                                        return (
                                            <>
                                                <div className="mobile-account-row link" onClick={openDetailsDialog} id="mobile-row">
                                                    <div className="mobile-account-logo col">
                                                        <img id={idx}
                                                            src={account.logo !== "nologo"
                                                                ? account.logo
                                                                : BANK_LOGOS[account.provider]} />
                                                    </div>
                                                    <div className="mobile-account-provider col" id={idx}>
                                                        {account.provider}
                                                    </div>
                                                    <div className="mobile-account-number col" id={idx}>
                                                        {"****" + account.account_number[5] + account.account_number[6]
                                                            + account.account_number[7]}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                            <AccountDetailsDialogue account={detailsAccount} display={displayAccountDetails}
                                toggleDialogue={toggleDetailsDialogue.bind(this)}
                                refreshData={refreshFinancialsData.bind(this)} />

                        </div>
                        <div className="tables-column flex-column">
                            <TransactionsTable tableName="Regular outgoings reported" className="financials-table"
                                transactions={transactions && transactions} accounts={accounts && accounts}
                                refreshTransactions={getTransactions.bind(this)} />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Banking