import http_api from "./http/http_api";

const getAccounts = () => http_api.get("/44968d3ddb237d5c8c9320019/account");

const getRegularTransactions = () => http_api.get("/44968d3ddb237d5c8c9320019/regular-transactions");
const postRegularTransaction = (body) => http_api.post("/44968d3ddb237d5c8c9320019/transaction", body);
const hideTransaction = (transaction_label) => http_api.post("/44968d3ddb237d5c8c9320019/hidden-transactions", {
    "label": transaction_label
})

const unhideTransaction = (transaction_label) => http_api.delete("/44968d3ddb237d5c8c9320019/hidden-transactions/" + transaction_label)

const getHiddenTransactions = () => http_api.get("/44968d3ddb237d5c8c9320019/hidden-transactions");

export default {
    getAccounts,
    getRegularTransactions,
    postRegularTransaction,
    hideTransaction,
    getHiddenTransactions,
    unhideTransaction
}